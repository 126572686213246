import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(53,103,50)'
        },
        secondary: {
            main: '#19857b',
        },
        action:{
            main: 'rgb(53, 103, 50)'
        },
        
        error: {
            main: 'rgb(211,47,47)',
        },
        background: {
            main: '#d32f2f',
        },
    },
});

export default theme;

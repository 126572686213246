import React, {useState, useEffect} from "react";
import {Grid, Typography} from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import {navigate} from "gatsby";
import firebase from "gatsby-plugin-firebase";
import {StyledFirebaseAuth} from "react-firebaseui"
import useAuthState from "../services/useAuthState";
import {Reservaciones} from "./reservaciones";
import {isLoggedIn, setToken, logoutGatsbyUser} from "../services/auth";


const Login = () => {
    const [uiConfig, setUiConfig] =useState({})
    const [user2, loading, fberror, auth] = useAuthState(firebase);
    const [error, setError] = useState(null)
    
    useEffect(()=>{
        if (auth !== undefined){
            setUiConfig({
                signInFlow: 'popup',
                signInOptions: [
                    {
                        provider:   firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                        customParameters: {hd: 'acguanacaste.ac.cr'},
                    }
                ],
                callbacks : {
                    signInSuccessWithAuthResult: (result) => {
                        //Save token on local storage and add it to http headers
                        auth.onIdTokenChanged(user => {
                            if (user != null && user.email.split('@')[1]=="acguanacaste.ac.cr" ) {
                                user.getIdToken().then(function (tokenId) {
                                    setToken(tokenId)
                                })
                            } else {
                                logoutGatsbyUser()
                                setError("Error al iniciar sesión: Usuario nulo")
                                navigate("/")
                            }
                        }, error => {
                            setError("Error al iniciar sesión: "+ error.toString());
                            navigate("/")
                        })
                        navigate("/app/reservaciones")
                    }
                }
            })
        }
        
    },[auth])
    
      if (isLoggedIn()) {
          navigate("/app/reservaciones")
        return (
            <Grid container>
                <Grid item md={12}>
                    <Reservaciones />
                </Grid>
            </Grid>
        )
    }
    
    return (
        <Grid container>
            {(error==null)?null:(
                <Grid item container xs={12}>
                    <Grid item container alignItems="center" style={{backgroundColor: "rgb(253, 236, 234)", padding: "1rem"}}>
                        <Grid item xs={1}>
                            <ReportProblemIcon style={{color: "#ad1d1d"}}/>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant={"body2"} style={{color: "#ad1d1d"}} >
                                {error}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid>
                <Typography>
                    Para ingresar al sistema utilice su correo institucional.
                </Typography>
                {(!loading) && (<StyledFirebaseAuth uiConfig={uiConfig}
                                    firebaseAuth={auth}
                />)}
            </Grid>
        </Grid>
    );
}


export default Login

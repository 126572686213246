/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"


import {Box, Container,Grid, makeStyles, Typography} from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import theme from "./theme"


import Header from "./header"
import "./layout.css"

const useStyles = makeStyles((theme) => ({
    footer: {
        borderTop: `0.2rem rgb(53,103,50) solid`,
        marginTop: `1.45rem`,
        paddingTop: `1rem`,
    },
}));

// A custom theme for this app


const Layout = ({children, sector}) => {
//   console.log("render");
    const classes = useStyles(theme);
    
    const sectorACG = sector
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    
    const email = () => {
        switch (sectorACG) {
            case "Marino":{
                return "reservaciones.sector.marino@acguanacaste.ac.cr";
            }
            case "Horizontes":{
                return "reservaciones.horizontes@acguanacaste.ac.cr";
            }
            case "Santa Rosa":{
                return "reservaciones.santa.rosa@acguanacaste.ac.cr";
            }
            case "Pailas":{
                return"reservaciones.pailas@acguanacaste.ac.cr";
            }
            default: return "ecoturismo@acguanacaste.ac.cr"
    
        }
    }
    
    const whatsapp = () => {
        switch (sectorACG) {
            case "Marino":{
                return "87358976";
            }
            case "Horizontes":{
                return "89973341";
            }
            case "Santa Rosa":{
                return "88869510";
            }
            case "Pailas":{
                return"88869510";
            }
            default: return "88869510"
        }
    }
    return (
        <MuiThemeProvider theme={theme}>
            <div>
            <Header siteTitle={data.site.siteMetadata.title}/>
            <Container maxWidth="md">
                <Box spacing={3} >
                    <main>{children}</main>
                </Box>
                <footer className={classes.footer} >
                    <Grid container display={"flex"} >
                        <Grid item xs={12} md={6} >
                            <Typography variant={"body2"}>
                            
                            
                            <a href="https://www.acguanacaste.ac.cr">Área de Conservación Guanacaste, <br /></a>
                            <a href="http://www.sinac.go.cr/">Sistema Nacional de Áreas de Conservación</a>  <br />
                            <a href="https://minae.go.cr/">Ministerio de Ambiente y Energía</a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Typography variant={"body2"}>
    
                                Email: <a href={"mailto:"+email()}>{email()}</a> <br />
                                Tel: <a href="tel:+50626665051">2666-5051</a> <br />
                            WhatsApp: <a href={"https://api.whatsapp.com/send?phone=506"+whatsapp()}>{whatsapp()}</a>
                            </Typography>
                        </Grid>
                    </Grid>
                    
                    
                </footer>
            </Container>
            </div>
        </MuiThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

import React, { useEffect, useState } from "react";
import {
    Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField,
    FormControl, Select, MenuItem, InputLabel, makeStyles, Typography, IconButton, Button
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { Link, navigate } from "gatsby";
import axios from "axios"
import { observer } from "mobx-react-lite";
import { autorun } from 'mobx'

import { useFilterStore } from "../context/filter";
import BuscarReservacion from "./buscarReservacion";
import moment from "moment";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import useTipoDeCambio from "./tdc";
import Ultimas from "./ultimas";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    escuela: {
        backgroundColor: "#a7cbe8"
    },
    tabs: {
        backgroundColor: "white",
        color: "black"
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export function Reservaciones() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.tabs}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Reservaciones por Sector" {...a11yProps(0)} />
                    <Tab label="Últimas Reservaciones" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <PorSector />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Ultimas
                    limit={20}
                    sort={'creado'}
                />
            </TabPanel>
        </div>
    );
}
const PorSector = observer(() => {
    PorSector.defaultProps = {
        tipo: "turistas"
    }
    const [data, setData] = useState([])
    const filter = useFilterStore()
    const classes = useStyles();
    const [showEdit, setShowEdit] = useState(false)
    const action = "turistas";
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0)

    const formatColones = { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'CRC' };
    const tdc = useTipoDeCambio().toLocaleString('es-CR', formatColones)

    async function getReservation() {
        await axios.get(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php', {
            params: {
                action,
                sector: encodeURI(filter.sector),
                desde: filter.desde,
                hasta: filter.hasta,
                sort: "hora",
                direction: "desc"
            }
        })
            .then(function (response) {
                const info = response.data
                setData(info)
                setError(null)

            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                    setData([])
                } else {
                    setError("Hubo un error al conseguir la información de la reserva. " + error)
                    setData([])
                }
            })
    }

    const handleDownload = (data) => {
        let report = []
        const processRow = function (row) {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                let innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        const headers = [
            "Reservación",
            (filter.sector === "Naranjo") ? "Placa" : "Depósito",
            "Hora",
            "Nombre",
            "Total Pax",
        ];

        let csvFile = processRow(headers);
        data.forEach((reservacion) => {
            let deposito = reservacion.detalles.filter(input => input.name === 'deposito')[0]
            if (typeof deposito === "undefined")
                deposito = "Pago con tarjeta al ingresar al Parque";
            else
                deposito = deposito.value

            if (filter.sector === "Naranjo") {
                let placa = reservacion.detalles.filter(input => input.name === 'placa')[0]
                if (typeof placa !== "undefined")
                    deposito = placa.value
            }

            let nombre = reservacion.detalles.filter(input => input.name === 'nombre')[0]
            nombre = (typeof nombre != 'undefined' && nombre.value.length !== 0) ? nombre.value : reservacion.pasajeros[0].nombrePax

            let cantidad = reservacion.pasajeros.reduce(function calcSTD(accumulator, pax) {
                return accumulator + pax.cantidad;
            }, 0)
            if (isNaN(cantidad)) {
                cantidad = reservacion.pasajeros.length;
            }
            let row = [
                reservacion.reservacion,
                deposito,
                reservacion.detalles[1].value + ":00",
                nombre,
                cantidad,


            ]
            csvFile += processRow(row)
            report.push(row)
        });




        const file = new File([csvFile], filter.sector + "'-" + filter.desde + ".csv", {
            type: "text/csv",
        })

        const link = document.createElement('a')
        const url = URL.createObjectURL(file)

        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }

    const updateTotal = (pax) => {
        setTotal(total + pax)
    }

    //hay que usar este autorun para que corra el efecto en el cambio del store
    useEffect(() => autorun(() => {
        let max = moment(filter.desde).add(1, 'day').format('yyyy-MM-DD');
        filter.setHasta(max)
        
        getReservation()
        filter.sector === 'Marino' ? setShowEdit(true) : setShowEdit(false);
    }), [filter.desde, filter.sector]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={"h6"} component={"h1"}>
                    Reporte de reservaciones Sector {filter.sector}, {filter.displayDesde}
                </Typography>
            </Grid>
            <Grid item sm={12} container>
                <Grid item sm={6} md={3}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="date"
                            label="Fecha"
                            type="date"
                            format="dd/MM/yyyy"
                            fullWidth
                            required
                            defaultValue={filter.desde}
                            inputProps={{}}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => {
                                const fecha = e.target.value;
                                filter.setDesde(fecha)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={3}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                        <Select
                            labelId="sector-label"
                            id="sector"
                            value={filter.sector}
                            onChange={function (event) {
                                const sector = event.target.value;
                                filter.setSector(sector)
                            }}
                        >
                            <MenuItem value={"Marino"}>Marino</MenuItem>
                            <MenuItem value={"Santa Rosa"}>Santa Rosa</MenuItem>
                            <MenuItem value={"Pailas"}>Pailas</MenuItem>
                            <MenuItem value={"Horizontes"}>Horizontes</MenuItem>
                            <MenuItem value={"Santa María"}>Santa María</MenuItem>
                            <MenuItem value={"Naranjo"}>Naranjo</MenuItem>
                            <MenuItem value={"Murciélago"}>Murciélago</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={3}>
                    <BuscarReservacion />
                </Grid>
                <Grid item sm={6} md={3}>
                    <Typography>
                        Tipo de Cambio: {tdc}
                    </Typography>
                    {(filter.sector !== "Marino" && data.length > 0) ? (
                        <>
                            <Button variant="contained" color="primary" onClick={() => { handleDownload(data) }}>Bajar reporte</Button>
                        </>

                    ) : (null)}

                </Grid>
            </Grid>
            {(error == null) ? null : (
                <Grid item container sm={12}>
                    <Grid item container alignItems="center" style={{ backgroundColor: "rgb(253, 236, 234)", padding: "1rem" }}>
                        <Grid item sm={1}>
                            <ReportProblemIcon style={{ color: "#ad1d1d" }} />
                        </Grid>
                        <Grid item sm={11}>
                            <Typography variant={"body2"} style={{ color: "#ad1d1d" }} >
                                {error}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <TablaReservaciones
                sector={filter.sector}
                data={data}
                onAfterDelete={getReservation}
                showEdit={showEdit}
            />
        </Grid>
    )
})

const TablaReservaciones = React.memo((props) => {
    const clases = useStyles();
    const [error, setError] = useState("");


    const handleDelete = (reservacion) => {

        axios.get(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php?action=oneReservation&reservacion=' + reservacion).then((response) => {

            if (typeof window !== "undefined") {
                const answer = window.confirm("¿Desea borrar la reservación " + reservacion + " ?")
                if (answer) {
                    borrarReservacion(reservacion)
                }
            }

        })


    }

    async function borrarReservacion(reservacion) {

        const allData = new FormData();

        allData.append('reservacion', reservacion)
        allData.append('action', 'deleteReservacion')
        setError("")
        await axios.post(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php', allData)
            .then(function (response) {
                const info = response.data

                if (info.error !== "") {
                    props.onAfterDelete()
                } else {
                    setError(info.error)
                }

            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                } else {
                    setError("Hubo un error al conseguir la información de la reserva. " + error)
                }
            })
    }

    const totalPax = () => {
        let acc = 0
        props.data.forEach((reservacion) => {

            acc = acc + reservacion.pasajeros.reduce(function calcSTD(accumulator, pax) {
                if (typeof pax.cantidad !== "undefined")
                    return accumulator + pax.cantidad;
                else {
                    return accumulator + 1
                }
            }, 0)

        })
        return acc
    }
    const some = totalPax();



    //
    return (
        <>
            <Typography variant={"body2"}>
                Total de Pasajeros {some}

            </Typography>
            {(error !== "") && (<Alert severity={"error"}>{error}</Alert>)}
            <Table
                aria-label="simple table"
                stickyHeader={true}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Reservación</TableCell>
                        <TableCell>{(props.sector === "Naranjo") ? ("Placa") : ("No. Depósito")}</TableCell>
                        <TableCell>Hora</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Pax</TableCell>
                        {(props.showEdit) && (<TableCell>Actividad</TableCell>)}
                        <TableCell>Borrar</TableCell>
                        <TableCell>Editar</TableCell>
                    </TableRow>
                </TableHead>
                {(props.data.length <= 0) ? <TableBody>
                    <tr><td colSpan={8}>
                        <Typography variant={"h6"} component={"h1"}>
                            No hay reservaciones para este día
                        </Typography>
                    </td></tr>
                </TableBody> :
                    <TableBody>
                        {props.data.map((reservacion, index) => {

                            let colorCamping = "";
                            console.log('reservacion de camping: ' + reservacion.camping);
                            console.log(reservacion);
                            if (typeof reservacion.camping !== "undefined") {
                                console.log('entre');
                                if (reservacion.camping) {
                                    colorCamping = "#d5ecc7";
                                } else {
                                    colorCamping = "#ffffff";
                                }
                            }

                            let deposito = reservacion.detalles.filter(input => input.name === 'deposito')[0]
                            if (typeof deposito === "undefined")
                                deposito = "Pago con tarjeta al ingresar al Parque";
                            else
                                deposito = deposito.value

                            if (props.sector === "Naranjo") {
                                let placa = reservacion.detalles.filter(input => input.name === 'placa')[0]
                                if (typeof placa !== "undefined")
                                    deposito = placa.value
                            }

                            let nombre = reservacion.detalles.filter(input => input.name === 'nombre')[0]
                            nombre = (typeof nombre != 'undefined' && nombre.value.length !== 0) ? nombre.value : reservacion.pasajeros[0].nombrePax

                            let cantidad = reservacion.pasajeros.reduce(function calcSTD(accumulator, pax) {
                                return accumulator + pax.cantidad;
                            }, 0)
                            if (isNaN(cantidad)) {
                                cantidad = reservacion.pasajeros.length;
                            }


                            const clase = (reservacion.tipo === "escuela") ? clases.escuela : null;
                            return (
                                <TableRow key={index} className={clase} style={{ backgroundColor: colorCamping }}>
                                    <TableCell component="th" scope="row">
                                        <Link
                                            to={"/app/reservacion?reservacion=" + reservacion.reservacion}>{reservacion.reservacion}</Link>
                                    </TableCell>
                                    <TableCell>{deposito} </TableCell>
                                    <TableCell>{reservacion.detalles[1].value + ":00"}</TableCell>
                                    <TableCell>{nombre} </TableCell>
                                    <TableCell>{cantidad}</TableCell>
                                    {(props.showEdit) && (
                                        <TableCell>{reservacion.detalles[11]?.value}</TableCell>)}
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                handleDelete(reservacion.reservacion)
                                            }}>
                                            <DeleteForeverIcon
                                                color="error"
                                            />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                navigate("/app/edit?reservacion=" + reservacion.reservacion)
                                            }}>
                                            <EditIcon
                                                color="secondary"
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                }
            </Table>
        </>

    )
})


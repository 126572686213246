import React, {useEffect, useState} from "react";
import axios from "axios";
import {IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {TablaReservaciones} from "./reservaciones";
import Alert from "@material-ui/lab/Alert";
import {Link, navigate} from "gatsby";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
    escuela : {
        backgroundColor: "#a7cbe8"
    }
}));

const Ultimas = (props) => {
    const clases = useStyles();
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    async function getReservation() {
        await axios.get(process.env.GATSBY_BACKEND_SERVER + 'endpoint.php', {
            params: {
                action:'ultimas',
                sort : "creado",
                direction: "25"
            }
        })
            .then(function (response) {
                const info = response.data
                setData(info)
                setError(null)
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    setError("Su sesión ha expirado. Recargue la página, si el error persiste desconéctese y vuelva a ingresar con su correo y clave. " + error)
                    setData([])
                } else {
                    setError("Hubo un error al conseguir la información de la reserva. " + error)
                    setData([])
                }
            })
    }
    useEffect(()=>{
        getReservation()
    },[])
    return (
        <div>
            <Typography variant={"h6"} component={"h1"}>Reservaciones hechas recientemente</Typography>
            {(error === null)?null:(
                <Typography>
                    {error}
                </Typography>
            )}


            {(error !== null) && (<Alert severity={"error"}>{error}</Alert>)}
            <Table
                aria-label="simple table"
                stickyHeader={true}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Reservación</TableCell>
                        <TableCell>Sector</TableCell>
                        <TableCell>No. Depósito</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Pax</TableCell>
                        <TableCell>Actividad</TableCell>
                        <TableCell>Creado </TableCell>
                        <TableCell>Editar</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((reservacion, index) => {
                        let deposito = reservacion.detalles.filter(input => input.name === 'deposito')[0]
                        if (typeof deposito === "undefined")
                            deposito = "Pago con tarjeta al ingresar al Parque";
                        else
                            deposito = deposito.value

                        deposito =  deposito.substring(0,17)

                        let nombre = reservacion.detalles.filter(input => input.name === 'nombre')[0]
                        nombre = (typeof nombre != 'undefined' && nombre.value.length !== 0) ? nombre.value : reservacion.pasajeros[0].nombrePax

                        let cantidad = reservacion.pasajeros.reduce(function calcSTD(accumulator, pax) {
                            return accumulator + pax.cantidad;
                        }, 0)
                        if (isNaN(cantidad)) {
                            cantidad = reservacion.pasajeros.length;
                        }
                        const fecha = reservacion.fcreado;
                        const clase = (reservacion.tipo === "escuela") ? clases.escuela : null;
                        return (
                            <TableRow key={index} className={clase}>
                                <TableCell component="th" scope="row">
                                    <Link
                                        to={"/app/reservacion?reservacion=" + reservacion.reservacion}>{reservacion.reservacion}</Link>
                                </TableCell>
                                <TableCell>{reservacion.sector} </TableCell>
                                <TableCell>{deposito} </TableCell>

                                <TableCell>{reservacion.fecha}</TableCell>
                                <TableCell>{nombre} </TableCell>
                                <TableCell>{cantidad}</TableCell>
                                {(true) && (
                                    <TableCell>{reservacion.detalles[11]?.value}</TableCell>)}
                                <TableCell>{fecha}</TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            navigate("/app/edit?reservacion=" + reservacion.reservacion)
                                        }}>
                                        <EditIcon
                                            color="secondary"
                                        />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default Ultimas
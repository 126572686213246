import axios from "axios";
import {useEffect, useState} from "react";

//Obtiene el tipo de cambio
export default function useTipoDeCambio() {
    const [tdc, setTdc] = useState(0)

    async function  getTipoDeCambio() {
        try {
            let res= await axios.get('https://sirevi.acguanacaste.ac.cr/getTC.php')
            const data = res.data;
            setTdc(data);
            return data;

        } catch (error) {
            throw new Error(error);
        }
    }
    useEffect(()=>{
        getTipoDeCambio();
    }, [])

    return tdc;
}
import React, {useContext, useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import firebase from "gatsby-plugin-firebase";
import {navigate} from "gatsby";
import {logoutGatsbyUser} from "../services/auth";
import {AuthContext} from "../context/auth";
import useAuthState from "../services/useAuthState";


const LogOut = () => {
    const {user, setUser} = useContext(AuthContext);
    const logOGU = () => logoutGatsbyUser()
    const [user2, loading, fberror, auth] = useAuthState(firebase);
    return (
        < Button variant="contained" color="secondary"
                 onClick={() => {
                     if (user){
                         auth.signOut();
                         logOGU();
                     }
                     navigate("/app/login");
                 } } > {(user)?"Desconectar":"Ingresar"}</Button>
    )
}

export default LogOut

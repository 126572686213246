import React, {useState} from 'react'
import {FormControl, IconButton, TextField} from "@material-ui/core";
import {SearchOutlined} from "@material-ui/icons";
import {navigate} from "gatsby";

const BuscarReservacion = () => {
    const [unaReservacion, setUnaReservacion] = useState("")
    
    function handleSearch() {
        if (unaReservacion !== "")
            navigate("/app/reservacion?reservacion=" + unaReservacion)
    }
    return (
        <div>
            <FormControl >
                <TextField label="Buscar reservación" id="unaReservacion"value={unaReservacion}
                           onChange={(event) => { setUnaReservacion(event.target.value)}}
                           onKeyUp={(event)=>{
                               if (event.key === 'Enter'){
                                   handleSearch()
                               }
                           }}
        
        
                />
            </FormControl>
            <FormControl>
                <IconButton  variant="contained"
                             color="secondary"
                             onClick={(event)=>{
                                 handleSearch()
                             }}
        
                >
                    <SearchOutlined />
                </IconButton>
            </FormControl>
        </div>
    )
}

export default BuscarReservacion

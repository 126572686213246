import PropTypes from "prop-types"
import React, {useState, useContext} from "react"
import {AuthContext} from "../context/auth";
import {
    Container,
    AppBar,
    Typography,
    Drawer,
    ListItemIcon,
    ListItem,
    Divider,
    ListItemText,
    List,
    Box,
    makeStyles,
    Toolbar,
    Grid
} from "@material-ui/core";
import * as headerStyles from "./header.module.scss"
import {navigate, Link} from 'gatsby'
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import LogOut from "./logOut";
import {isLoggedIn} from "../services/auth";

const useStyles = makeStyles((theme) => ({
    headerLink : {
        color:"white",
        textDecoration:"none"
    },
    tableRightBorder : { border:0, borderLeft:1, borderColor: 'white',borderStyle: 'solid', color: 'white'} // or borderTop: '1px solid red'
    
}));

const Header = ({siteTitle}) => {
    const [anchor, setAnchor] = useState("left")
    const [state, setState] = useState({
        left: false
    });
    const {user, setUser} = useContext(AuthContext);
    const classes = useStyles();
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        
        setState({...state, [anchor]: open});
    };
    
    const list = (anchor) => (
        <div
            className={"list"}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button onClick={() => {
                    if (!user)
                        navigate("/app/login")
                    else {
                        navigate('/app/reservaciones')
                    }
                }}>
                    <ListItemIcon> <HomeIcon/></ListItemIcon>
                    <ListItemText primary="Home"/>
                </ListItem>
                <Divider/>
                {(isLoggedIn()) && (
                    <>
                        <ListItem button onClick={() => navigate("/app/reservaciones")}><ListItemText
                            primary={"Reservaciones"}/></ListItem>
                        <ListItem button onClick={() => navigate("/app/escuelas")}><ListItemText
                            primary={"Escuelas"}/></ListItem>
                        <ListItem button onClick={() => navigate("/app/report")}><ListItemText
                            primary={"Reporte"}/></ListItem>
                        <ListItem button onClick={() => navigate("/app/reservacion")}><ListItemText
                            primary={"Ver una reservación"}/></ListItem>
                    </>
                )}
                <ListItem button onClick={() => navigate("/app/login")}><ListItemText primary={"Login"}/></ListItem>
            </List>
            <Divider/>
        </div>
    );
    return (
        <header className={headerStyles.header}>
            <AppBar position="static">
                <Container maxWidth="md">
                    <Toolbar>
                        <Grid container alignItems="center">
                            <Grid item xs={2} sm={2} md={1} direction="row" alignItems="center" container>
                                <div className={headerStyles.inline}>
                                    <React.Fragment key={anchor}>
                                        <MenuIcon fontSize={"large"}
                                                  onClick={toggleDrawer(anchor, true)}>Menú</MenuIcon>
                                        <Drawer anchor={anchor} open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}>
                                            {list(anchor)}
                                        </Drawer>
                                    </React.Fragment>
                                </div>
                            </Grid>
    
                            {(!isLoggedIn())? (
                                <Grid item xs={6} sm={2} md={7}>
                                    <div className={headerStyles.inline}>
                                        <Typography variant="h6" component="h1"
                                                    style={{margin: 0, paddingBottom: "2px"}} gutterBottom
                                                    className={siteTitle} onClick={toggleDrawer(anchor, true)}>
                                            Reservaciones ACG
                                        </Typography>
                                    </div>
                                </Grid>
                            ):(
                                <Grid item xs={6} md={7}>
                                    <div className={headerStyles.inline}>
                                        <Typography variant="h6" component="h1"
                
                                                    className={siteTitle}>
                                            <Link className={classes.headerLink}
                                                  to="/app/reservaciones"> Reservaciones </Link>
                                        </Typography>
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={4} md={4} container justifyContent={"flex-end"}>
                                <LogOut/>
                                <Box component={Grid} item md={1} display={{xs: "none", md: "block"}}>
                                    <a target={"_blank"} href={"https://www.acguanacaste.ac.cr/"} rel="noreferrer">
                                        <img style={{maxWidth: "3rem"}}
                                             src={'/images/ACG-logo.png'}
                                             alt="Logo Área de Conservación Guanacaste, Fuente de Vida y Desarollo"/>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
        </header>
    
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
